import { navigate } from "gatsby";
import { useEffect } from "react";
import React from "react";

export default function () {
  useEffect(() => {
    navigate("/it");
  }, []);

  return <div></div>;
}
